
/* Hero Secondary */
.hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    background: var(--primary);
    background-image: linear-gradient(to left, #066bc9, var(--tertiary), #066bc9);

    padding: 0rem 0rem 6rem 0rem;
    margin: 0rem 0rem 0rem 0rem;

    /* border: 0.2rem solid red; */
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 8rem 15% 8rem 15%;
}

/* Background Images */
.circleContainerLarge {
    position: absolute;
    z-index: 0;

    top: 40rem;
    left: -42rem;

    width: 80rem;
}

.circleContainerSmall {
    position: absolute;
    z-index: 0;

    top: 15rem;
    right: 10%;

    width: 15rem;
}

/* Text Content */
.textContainer {
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    color: #FFFFFF;

    text-align: center;
}

.title {
    font-size: var(--fontSizeTitleXL);
    font-weight: var(--fontWeightXL);

    margin: 2rem 0rem 0rem 0rem;
}

.description {
    font-size: var(--fontSizeTitleXS);
    font-weight: var(--fontWeightM);
    letter-spacing: var(--letterSpacingA);

    margin: 4rem 0rem 0rem 0rem;
}

/* Button */
.button {
    margin: 8rem 0rem 0rem 0rem;
}


@media screen and (max-width: 1440px) {
    /* Hero Secondary */
    .container {
        margin: 8rem 15% 6rem 15%;
    }

    /* Background Images */
    .circleContainerLarge {
        top: 36rem;
    }
}


@media screen and (max-width: 1200px) {
    /* Hero Secondary */
    .container {
        margin: 6rem 15% 4rem 15%;
    }

    /* Background Images */
    .circleContainerLarge {
        top: 32rem;
    }
}


@media screen and (max-width: 960px) {
    /* Text Content */
    .title {
        font-size: var(--fontSizeTitleL);
    }
    
    .description { 
        font-size: var(--fontSizeText2XL);
    }
}


@media screen and (max-width: 840px) {
    /* Hero Secondary */
    .container {
        margin: 6rem 10% 4rem 10%;
    }

    /* Background Images */
    .circleContainerLarge {
        top: 30rem;
    }

    .circleContainerSmall {
        top: 15rem;
        right: 5%;
    
        width: 15rem;
    }

    /* Text Content */
    .description { 
        margin: 3rem 0rem 0rem 0rem;
    }

    /* Button */
    .button {
        margin: 6rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    /* Background Images */
    .circleContainerLarge {
        top: 30rem;
        width: 65rem;
    }

    .circleContainerSmall {
        right: 5%;
        width: 12rem;
    }

    /* Text Content */
    .title {
        font-size: var(--fontSizeTitleM);
    }
}


@media screen and (max-width: 600px) {
    /* Hero Secondary */
    .container {
        margin: 6rem 5% 4rem 5%;
    }

    /* Background Images */
    .circleContainerLarge {
        left: -38rem;
        width: 55rem;
    }

    .circleContainerSmall {
        right: 2.5%;
        width: 10rem;
    }
}


@media screen and (max-width: 480px) {
    /* Hero Secondary */
    .container {
        margin: 6rem 5% 2rem 5%;
    }

    /* Background Images */
    .circleContainerSmall {
        right: -10%;
    }

    /* Text Content */
    .title {
        font-size: var(--fontSizeTitleS);
    }

    .description { 
        font-size: var(--fontSizeTextL);
        margin: 2rem 0rem 0rem 0rem;
    }

    /* Button */
    .button {
        margin: 4rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 420px) {
    /* Background Images */
    .circleContainerLarge {
        left: -40rem;
        width: 55rem;
    }

    .circleContainerSmall {
        right: -15%;
    }
}


@media screen and (max-width: 360px) {
    /* Hero Secondary */
    .container {
        margin: 4rem 2.5% 0rem 2.5%;
    }

    /* Background Images */
    .circleContainerLarge {
        top: 28rem;
        left: -40rem;
        width: 55rem;
    }

    .circleContainerSmall {
        right: -20%;
    }

    /* Text Content */
    .title {
        font-size: var(--fontSizeTitleXS);
    }

    .description { 
        font-size: var(--fontSizeTextM);
        margin: 1rem 0rem 0rem 0rem;
    }

    /* Button */
    .button {
        margin: 3rem 0rem 0rem 0rem;
    }
}
