
/* Contact Form */
.formSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    margin: 2rem 0rem 0rem 0rem;
}

.formTitle {
    color: #222222;
    font-size: var(--fontSizeTitleM);
    font-weight: var(--fontWeightXL);

    margin: 0rem 0rem 0rem 0rem;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70%;

    margin: 4rem 0rem 0rem 0rem;
}

.formName {
    width: 100%;

    font-size: var(--fontSizeTitleXS);

    margin: 0rem 0rem 0rem 0rem;
}

.formEmail {
    width: 100%;

    font-size: var(--fontSizeTitleXS);

    margin: 0.8rem 0rem 0rem 0rem;
}

.formMessage {
    width: 100%;

    font-size: var(--fontSizeTitleXS);

    margin: 0.8rem 0rem 0rem 0rem;
}

.formInput {
    width: 100%;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: var(--fontSizeTextL);
    color: #353535;

    height: 4rem;

    text-indent: 0rem;

    padding: 1rem 1rem;

    border: none;
    border-radius: 0.25rem;
    outline: none !important;
    border: 0.2rem solid var(--primary);
}

.formInput:focus {
    outline: none !important;
    border: 0.25rem solid var(--primary);
}

.formInput::placeholder {
    color: var(--primary);
}

.formInputTextArea {
    width: 100%;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: var(--fontSizeTextL);
    color: #353535;
    resize: vertical;
    min-height: 16rem;
    max-height: 32rem;
    height: 16rem;

    text-indent: 0rem;

    padding: 1rem 1rem;

    border: none;
    border-radius: 0.25rem;
    outline: none !important;
    border: 0.2rem solid var(--primary);
}

.formInputTextArea:focus {
    outline: none !important;
    border: 0.25rem solid var(--primary);
}

.formInputTextArea::placeholder {
    color: var(--primary);
    
}

.formCheck {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 6rem;
    padding-bottom: 6rem;
}

.formCheckMessage {
    display: flex;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: var(--fontSizeTextM);
    font-weight: var(--fontWeightM);
    color: #555555;

    padding-left: 1rem;

    transition: 0.4s;
}

.formCheckMessageHidden {
    display: none;

    transition: 0.4s;
}

.formButton {
    margin: 0rem 0rem 8rem 0rem;
}


@media screen and (max-width: 1440px) {
    .formContainer {
        width: 80%;
        margin: 3rem 0rem 0rem 0rem;
    }

    .formButton {
        margin: 0rem 0rem 4rem 0rem;
    }
}


@media screen and (max-width: 1200px) {
    .formTitle {
        font-size: var(--fontSizeTitleS);
    }

    .formContainer {
        width: 85%;
        margin: 2.5rem 0rem 0rem 0rem;
    }

    .formButton {
        margin: 0rem 0rem 2rem 0rem;
    }
}


@media screen and (max-width: 960px) {
    .formContainer {
        width: 90%;
        margin: 2.5rem 0rem 0rem 0rem;
    }

    .formButton {
        margin: 0rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 840px) {
    .formContainer {
        width: 80%;
        margin: 2.5rem 0rem 0rem 0rem;
    }

    .formButton {
        margin: 0rem 0rem 8rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    .formContainer {
        width: 85%;
        margin: 2.5rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    .formContainer {
        width: 90%;
        margin: 2.5rem 0rem 0rem 0rem;
    }

    .formButton {
        margin: 0rem 0rem 6rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    .formTitle {
        font-size: var(--fontSizeTitleXS);
    }

    .formContainer {
        width: 92.5%;
        margin: 2rem 0rem 0rem 0rem;
    }
    
    .formEmail {
        margin: 0.6rem 0rem 0rem 0rem;
    }
    
    .formMessage {
        margin: 0.6rem 0rem 0rem 0rem;
    }
    
    .formInput {
        font-size: var(--fontSizeTextM);
    }
    
    .formInputTextArea {
        font-size: var(--fontSizeTextM);
    }
    
    .formCheckMessage {
        font-size: var(--fontSizeTextS);
    }
    
    .formButton {
        margin: 0rem 0rem 4rem 0rem;
    }
}


@media screen and (max-width: 360px) {

    .formContainer {
        width: 95%;
        margin: 1.5rem 0rem 0rem 0rem;
    }
    
    .formEmail {
        margin: 0.4rem 0rem 0rem 0rem;
    }
    
    .formMessage {
        margin: 0.4rem 0rem 0rem 0rem;
    }
    
    .formInput {
        font-size: var(--fontSizeTextS);
        height: 3.6rem;
    }
    
    .formInputTextArea {
        font-size: var(--fontSizeTextS);
    }
    
    .formCheckMessage {
        font-size: var(--fontSizeTextXS);
    }
}
