.section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
}

.container {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;

    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto auto;
    grid-column-gap: 5%;

    margin: 8rem 15% 8rem 15%;
}

.title {
    grid-column: 1;
    grid-row: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;

    color: #222222;
    font-size: var(--fontSizeTitleL);
    font-weight: var(--fontWeightXL);

    padding-right: 4rem;
    border-right: 0.2rem solid var(--primary);

    margin: 0rem 0rem 0rem 0rem;
}

.description {
    grid-column: 2;
    grid-row: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    color: #666666;
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);

    
    padding-left: 4rem;

    margin: 0rem 0rem 0rem 0rem;
}

.button {
    grid-column: 1 / 3;
    grid-row: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 8rem 0rem 0rem 0rem;
}


@media screen and (max-width: 1440px) {
    .container {
        grid-column-gap: 2.5%;

        margin: 6rem 12.5% 6rem 12.5%;
    }
}


@media screen and (max-width: 1200px) {
    .container {
        grid-column-gap: 2.5%;

        margin: 6rem 10% 6rem 10%;
    }

    .title {
        font-size: var(--fontSizeTitleM);
        font-weight: var(--fontWeightXL);
    
        padding-right: 2rem;
        border-right: 0.2rem solid var(--primary);
    
        margin: 0rem 0rem 0rem 0rem;
    }
    
    .description {
        font-size: var(--fontSizeTextM);
        font-weight: var(--fontWeightM);
    
        
        padding-left: 2rem;
    
        margin: 0rem 0rem 0rem 0rem;
    }
    
}


@media screen and (max-width: 960px) {
    .container {
        grid-column-gap: 2.5%;

        margin: 4rem 7.5% 4rem 7.5%;
    }
}


@media screen and (max-width: 840px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .title {
        padding: 0rem 0rem 2rem 0rem;
        border-right: none;

        -webkit-box-shadow: 0rem 4rem 0rem -3.65rem var(--primary);
        -moz-box-shadow: 0rem 4rem 0rem -3.65rem var(--primary);
        box-shadow: 0rem 4rem 0rem -3.65rem var(--primary);
    
        margin: 0rem 0rem 4rem 0rem;
    }

    .description {
        text-align: center;
        padding: 0rem;
    }

    .button {
        margin: 4rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    .container {
        margin: 4rem 5% 4rem 5%;
    }
}

@media screen and (max-width: 600px) {
    .title {
        font-size: var(--fontSizeTitleS);
        font-weight: var(--fontWeightXL);
    
        padding: 0rem 0rem 2rem 0rem;

        -webkit-box-shadow: 0rem 3rem 0rem -2.85rem var(--primary);
        -moz-box-shadow: 0rem 3rem 0rem -2.85rem var(--primary);
        box-shadow: 0rem 3rem 0rem -2.85rem var(--primary);
    
        margin: 0rem 0rem 3rem 0rem;
    }
}

@media screen and (max-width: 480px) {
    .container {
        align-items: flex-start;
    }

    .title {
        padding: 0rem 0rem 0rem 0rem;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin: 0rem 0rem 2rem 0rem;

    }

    .description {
        text-align: start;
        padding: 0rem;
    }

    .button {
        align-self: center;
    }
}


@media screen and (max-width: 360px) {
    .title {
        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeightXL);
    }

    .description {
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightM);
    }
}
