:root {
  /* Colors */
  --primary: #1480E3; /* Normal */
  --secondary: #1D8DF4; /* Lighter */
  --tertiary: #0479E5; /* Darker */

  /* Letter Spacing */
  --letterSpacingA: 0.05rem;
  --letterSpacingB: 0.1rem;
  --letterSpacingC: 0.15rem;

  /* Font Weight */
  --fontWeight2XS: 100;
  --fontWeightXS: 200;
  --fontWeightS: 300;
  --fontWeightM: 400;
  --fontWeightL: 500;
  --fontWeightXL: 600;
  --fontWeight2XL: 700;

  /* Font Size Title */
  --fontSizeTitleXS: 2.4rem;
  --fontSizeTitleS: 3.0rem;
  --fontSizeTitleM: 3.6rem;
  --fontSizeTitleL: 4.0rem;
  --fontSizeTitleXL: 4.4rem;
  --fontSizeTitle2XL: 5rem;

  /* Font Size Text */
  --fontSizeTextXS: 1.2rem;
  --fontSizeTextS: 1.4rem;
  --fontSizeTextM: 1.6rem;
  --fontSizeTextL: 1.8rem;
  --fontSizeTextXL: 2.0rem;
  --fontSizeText2XL: 2.2rem;
}

* {
  box-sizing: border-box;
  font-size: 62.5%; /* default: 16px; 16px x 62.5% = 10px = 1rem */
}

html,
body {
padding: 0;
margin: 0;
font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
color: inherit;
text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--fontWeightXL);
  margin: 0rem;
  font-size: var(--fontSizeTitleM);
  letter-spacing: var(--letterSpacingA);
}

p {
  letter-spacing: var(--letterSpacingC);
  line-height: 170%;
  font-weight: var(--fontWeightL);
  font-size: var(--fontSizeTextL);
}


/* Fonts */
@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
