
/* Hero Componant for Index Page */
.hero {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    background: var(--primary);
    background-image: linear-gradient(to left, #066bc9, var(--tertiary), #066bc9);

    padding: 0rem 0rem 0rem 0rem;
    margin: 0rem 0rem 0rem 0rem;

    /* border: 0.2rem solid red; */
}

/* Hero Grid Container */
.container {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;

    grid-template-columns: 1fr 1.25fr;
    grid-template-rows: auto auto;
    grid-column-gap: 10%;

    margin: 8rem 15% 8rem 15%;
}

/* Background Images */
.circleContainerLarge {
    position: absolute;
    z-index: 0;

    top: 40rem;
    left: -42rem;

    width: 80rem;
}

.circleContainerSmall {
    position: absolute;
    z-index: 0;

    top: 15rem;
    right: 5%;

    width: 15rem;
}

/* Mobile Header Content */
.headerMobileContainer {
    display: none;
}

.headerMobile {
    display: none;
}

/* Hero Image */
.imageContainer {
    grid-column: 2;
    grid-row: 1;

    z-index: 1;

    width: 100%;
    height: auto;
}

/* Text Content */
.textContainer {
    grid-column: 1;
    grid-row: 1;

    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    color: #FFFFFF;
}

.header {
    margin: 0rem;
    
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);
}

.title {
    margin: 2rem 0rem 0rem 0rem;

    font-size: var(--fontSizeTitleM);
    font-weight: var(--fontWeightXL);
}

.description {
    margin: 2rem 0rem 0rem 0rem;

    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightS);
    letter-spacing: var(--letterSpacingC);
}

/* Buttons */
.buttonContainer {
    grid-column: 1 / 3;
    grid-row: 2;

    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
}

.buttonLeft {
    margin: 4rem 2rem 0rem 0rem;
}

.buttonRight {
    margin: 4rem 0rem 0rem 0rem;
}


@media screen and (max-width: 1440px) {
    /* Hero Grid Container */
    .container {
        grid-column-gap: 5%;

        margin: 6rem 12.5% 6rem 12.5%;
    }
}


@media screen and (max-width: 1200px) {
    /* Hero Grid Container */
    .container {
        grid-column-gap: 2.5%;
        margin: 6rem 10% 6rem 12.5%;
    }

    /* Background Images */
    .circleContainerLarge {
        top: 36rem;
        left: -30rem;
        width: 60rem;
    }

    .circleContainerSmall {
        right: 7.5%;
        width: 10rem;
    }
    
    /* Text Content */
    .header {
        font-size: var(--fontSizeTextM);
    }

    .title {
        font-size: var(--fontSizeTitleS);
        margin: 1.5rem 0rem 0rem 0rem;
    }

    .description {
        font-size: var(--fontSizeTextL);
        letter-spacing: var(--letterSpacingB);
        margin: 1.5rem 0rem 0rem 0rem;
    }

    /* Buttons */
    .buttonLeft {
        margin: 2rem 1.5rem 0rem 0rem;
    }

    .buttonRight {
        margin: 2rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 960px) {
    /* Hero Grid Container */
    .container {
        margin: 6rem 7.5% 6rem 12.5%;
    }

    /* Background Images */
    .circleContainerSmall {
        right: 7.5%;
        width: 8rem;
    }

    /* Text Content */
    .title {
        margin: 1.5rem 0rem 0rem 0rem;

        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeightXL);
    }

    .description {
        font-size: var(--fontSizeTextM);
        letter-spacing: var(--letterSpacingA);
        margin: 1rem 0rem 0rem 0rem;
    }

    /* Buttons */
    .buttonLeft {
        margin: 1.5rem 1rem 0rem 0rem;
    }

    .buttonRight {
        margin: 1.5rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 840px) {
    /* Hero Flex Container */
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        margin: 4rem 7.5% 6rem 7.5%;
    }

    /* Background Images */
    .circleContainerLarge {
        top: 50rem;
        width: 50rem;
    }

    /* Mobile Header Content */
    .headerMobileContainer {
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        color: #FFFFFF;

        width: 90%;
    }

    .headerMobile {
        display: block;
        font-size: var(--fontSizeTextL);
        font-weight: var(--fontWeightM);
        margin: 0.5rem 0rem 2rem 0rem;
    }
    
    /* Hero Image */
    .imageContainer {
        width: 60%;
    }

    /* Text Content */
    .textContainer {
        z-index: 1;
    
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        color: #FFFFFF;
    }

    .header {
        display: none;
    }

    .title {
        font-size: var(--fontSizeTitleS);
        margin: 2rem 0rem 0rem 0rem;
    }

    .description {
        text-align: center;
        font-size: var(--fontSizeTextL);
        letter-spacing: var(--letterSpacingB);
        margin: 1.5rem 0rem 0rem 0rem;
    }

    /* Buttons */
    .buttonContainer {
        justify-content: center;
    }

    .buttonLeft {
        margin: 2rem 2rem 0rem 0rem;
    }

    .buttonRight {
        margin: 2rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    /* Hero Flex Container */
    .container {
        margin: 4rem 5% 6rem 5%;
    }

    /* Background Images */
    .circleContainerLarge {
        top: 46rem;
    }

    /* Hero Image */
    .imageContainer {
        width: 70%;
    }

    /* Text Content */
    .title {
        margin: 1rem 0rem 0rem 0rem;
    }

    .description {
        letter-spacing: var(--letterSpacingA);
        margin: 1rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    /* Hero Flex Container */
    .container {
        margin: 3rem 5% 4rem 5%;
    }

    /* Mobile Header Content */
    .headerMobile {
        font-size: var(--fontSizeTextM);
        margin: 0rem 0rem 2rem 0rem;
    }

    /* Hero Image */
    .imageContainer {
        width: 80%;
    }

    /* Text Content */
    .title {
        font-size: var(--fontSizeTitleXS);
    }

    .description {
        font-size: var(--fontSizeTextM);
    }

    /* Buttons */
    .buttonLeft {
        margin: 1.5rem 2rem 0rem 0rem;
    }

    .buttonRight {
        margin: 1.5rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    /* Hero Flex Container */
    .container {
        margin: 2rem 5% 4rem 5%;
    }

    /* Mobile Header Content */
    .headerMobileContainer {
        width: 100%;
    }

    .headerMobile {
        font-size: var(--fontSizeTextS);
        margin: 0rem 0rem 1.5rem 0rem;
    }

    /* Hero Image */
    .imageContainer {
        width: 85%;
        margin: 0rem 0rem 0rem 1rem;
    }

    /* Text Content */
    .title {
        text-align: start;
        width: 100%;
        font-weight: var(--fontWeightL);
    }

    .description {
        text-align: start;
        width: 100%;
        margin: 0.5rem 0rem 0rem 0rem;
    }

    /* Buttons */
    .buttonContainer {
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    .buttonLeft {
        margin: 1.5rem 0.5rem 0rem 0.5rem;
    }

    .buttonRight {
        margin: 2rem 0.5rem 0rem 0.5rem;
    }
}


@media screen and (max-width: 360px) {
    /* Hero Flex Container */
    .container {
        margin: 1rem 2.5% 2.5rem 2.5%;
    }

    /* Mobile Header Content */
    .headerMobile {
        margin: 0rem 0rem 1rem 0rem;
    }

    /* Hero Image */
    .imageContainer {
        width: 90%;
    }

    /* Text Content */
    .title {
        font-weight: var(--fontWeightM);
    }

    .description {
        font-size: var(--fontSizeTextS);
        letter-spacing: var(--letterSpacingB);
    }

    /* Buttons */
    .buttonLeft {
        margin: 1rem 0.5rem 0rem 0.5rem;
    }

    .buttonRight {
        margin: 1.5rem 0.5rem 0rem 0.5rem;
    }
}
