/* Nav */
.navMain {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 8rem;
    padding: 8rem 1rem 6rem 1rem;

    background: var(--primary);
    background-image: linear-gradient(to left, #066bc9, var(--tertiary), #066bc9);

    position: sticky;
    width: 100%;
    top: 0;
    z-index: 999;

    transition: 0.4s;

    /* border: 0.2rem solid red; */
}

.navScroll {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 8rem;
    padding: 3rem 1rem 3rem 1rem;

    background: var(--primary);
    background-image: linear-gradient(to left, #066bc9, var(--tertiary), #066bc9);

    position: sticky;
    width: 100%;
    top: 0;
    z-index: 999;

    -webkit-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);

    transition: 0.2s;
}

.navDesktop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 80%;
    max-width: 200rem;
}

.title {
    margin: 0rem 0rem 0rem 4rem;
}

.logoMain {
    width: 12rem;
    height: 12rem;
    background-image: url('../../assets/images/ubitech_logo.png');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position-x: center;
    background-position-y: center;

    cursor: pointer;

    transition: 0.6s;
}

.logoScroll {
    width: 8rem;
    height: 8rem;
    background-image: url('../../assets/images/ubitech_logo.png');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position-x: center;
    background-position-y: center;

    cursor: pointer;

    transition: 0.2s;

    margin: 0rem 0rem 0rem 2rem;
}

.menuList {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    list-style-type: none;

    padding: 0rem;
    margin: 0rem;
}

.menuLink {
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: var(--fontSizeTitleXS);
    font-weight: var(--fontWeightL);

    cursor: pointer;
    text-decoration: none;

    padding: 1rem 1rem;
    margin: 0rem 6rem 0rem 6rem;
}

.menuLink,
.menuLink:after,
.menuLink:before {
  transition: all .5s;
}

.menuLink {
  position: relative;
}

.menuLink:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #FFFFFF;
  height: 0.1rem;
}

.menuLink:hover:after {
  width: 100%;
}

.navMobile {
    display: none;
}

.none {
    display: none;
}


@media screen and (max-width: 1200px) {
    .menuLink {
        margin: 0rem 5rem 0rem 5rem;
    }
}


@media screen and (max-width: 960px) {
    .menuLink {
        margin: 0rem 3rem 0rem 3rem;
    }
}


@media screen and (max-width: 840px) {
    .navMain {
        flex-direction: column;
        justify-content: flex-start;

        height: 8rem;
        padding: 4rem 0rem 6rem 0rem;
    }

    .navScroll {
        flex-direction: column;
        justify-content: flex-start;

        height: 7rem;
        padding: 0rem 0rem 0rem 0rem;
    }

    .navDesktop {
        display: none;
    }

    .navMobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
    }

    .mobileContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 80%;
        
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        color: #fff;
    }
    
    .mobileTitle {
        display: flex;
        font-size: var(--fontSizeTitleXL);
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingB);
    
        margin: 2rem 0rem 0rem 0rem;
    }
    
    .mobileMenu {
        text-decoration: none;
    }
    
    .mobileMenuIconBurger {
        display: flex;
        cursor: pointer;

        font-size: var(--fontSizeTitleM);
        line-height: 4rem;

        padding: 1rem 0rem 0rem 0rem;
    
        margin: 0rem 0rem 0rem 0rem;
    }

    .mobileMenuIconX {
        display: flex;
        cursor: pointer;

        font-size: var(--fontSizeTitleL);
        line-height: 3rem;

        padding: 1rem 0rem 0rem 0rem;
    
        margin: 0rem 0.4rem 0rem 0rem;
    }
    
    .mobileMenuIconToggle {
        display: none;
    }

    .mobileMenuListContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 100vh;
        background-color: white;

        -webkit-box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.25);
        box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.25);

        overflow-y: auto;
    }
    
    .mobileMenuList {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        
        text-decoration: none;
        list-style-type: none;
        cursor: default;
    
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        color: #666;

        padding: 2rem 0rem 0rem 0rem;
        margin: 0rem 0rem 0rem 0rem;
    }
    
    .mobileMenuLinkPrimary {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        cursor: default;

        border-top: 0.05rem solid #bbbbbb;

        width: 80%;

        font-size: var(--fontSizeTitleS);
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingB);
        line-height: 1rem;
        color: #222;

        padding: 3.5rem 2rem 0rem 2rem;
        margin: 1rem 0rem 2rem 0rem;
    }

    .mobileMenuLinkPrimary:last-child {
        border-top: 0.05rem solid #bbbbbb;
        border-bottom: 0.05rem solid #bbbbbb;
        
        line-height: 1rem;

        padding: 3.5rem 2rem 3.5rem 2rem;
        margin: 1rem 0rem 12rem 0rem;
    }

    .mobileMenuLinkSecondary {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        width: 80%;

        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingB);
        line-height: 1rem;
        color: #222;

        padding: 1.5rem 2rem 2rem 4rem;
        margin: 0rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    .mobileTitle {
        font-size: var(--fontSizeTitleL);
        font-weight: var(--fontWeightM);
    }

    .mobileMenuIconBurger {
        font-size: var(--fontSizeTitleS);
    }

    .mobileMenuIconX {
        font-size: var(--fontSizeTitleM);
        padding: 1.5rem 0rem 0rem 0rem;
        margin: 0rem 0.2rem 0rem 0rem;
    }

    .mobileMenuList {
        margin: 0.25rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    .navScroll {
        height: 6.5rem;
    }

    .mobileTitle {
        font-size: var(--fontSizeTitleM);
        font-weight: var(--fontWeightS);
    }

    .mobileMenuIconBurger {
        font-size: var(--fontSizeTitleXS);
    }

    .mobileMenuIconX {
        font-size: var(--fontSizeTitleL);
        margin: 0rem 0rem 0rem 0rem;
    }

    .mobileMenuLinkPrimary {
        width: 90%;
        font-size: var(--fontSizeTitleXS);
        padding: 2.5rem 2rem 0rem 2rem;
        margin: 1rem 0rem 1.5rem 0rem;
    }

    .mobileMenuLinkSecondary {
        width: 90%;
        font-size: var(--fontSizeText2XL);
        padding: 1.5rem 2rem 1.5rem 4rem;
    }
}


@media screen and (max-width: 360px) {
    .navScroll {
        height: 5.5rem;
    }

    .mobileTitle {
        font-size: var(--fontSizeTitleS);
        font-weight: var(--fontWeightS);
        margin: 1.5rem 0rem 0rem 0rem;
    }

    .mobileMenuIconBurger {
        font-size: var(--fontSizeText2XL);
        line-height: 2.5rem;
    }

    .mobileMenuIconX {
        font-size: var(--fontSizeTitleM);
    }

    .mobileMenuLinkPrimary {
        font-size: var(--fontSizeText2XL);
        font-weight: var(--fontWeightM);
    }

    .mobileMenuLinkSecondary {
        font-size: var(--fontSizeTextXL);
        font-weight: var(--fontWeightM);
        padding: 1.5rem 0rem 1.5rem 3rem;
    }
}

