.section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
}

.container {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 10%;

    margin: 8rem 15% 2rem 15%;
}

.imageContainer1 {
    grid-column: 2;
    grid-row: 1;

    width: 100%;
    height: auto;
}

.imageContainer2 {
    grid-column: 1;
    grid-row: 1;

    width: 100%;
    height: auto;
}

.textContainer1 {
    grid-column: 1;
    grid-row: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.textContainer2 {
    grid-column: 2;
    grid-row: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.title {
    color: #222222;
    font-size: var(--fontSizeTitleM);
    font-weight: var(--fontWeightXL);

    margin: 0rem 0rem 0rem 0rem;
}

.description {
    color: #666666;
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);

    margin: 2rem 0rem 0rem 0rem;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 0rem 17.5% 0rem 17.5%;
}

.contentContainerHidden {
    display: none;
}

.content {
    color: #666666;
    font-size: var(--fontSizeTextL);
    font-weight: var(--fontWeightM);
    text-align: center;

    margin: 2rem 0rem 2rem 0rem;
}

.button {
    margin: 4rem 0rem 4rem 0rem;
}


@media screen and (max-width: 1440px) {
    .container {
        grid-column-gap: 5%;
    }
}


@media screen and (max-width: 1200px) {
    .container {
        margin: 8rem 12.5% 2rem 12.5%;
    }
    
    .description {
        font-size: var(--fontSizeTextM);
    }

    .contentContainer {
        margin: 0rem 15% 0rem 15%;
    }

    .content {
        margin: 2rem 0rem 1rem 0rem;
        font-size: var(--fontSizeTextM);
    }
}


@media screen and (max-width: 960px) {
    .container {
        grid-column-gap: 2.5%;
    
        margin: 6rem 10% 2rem 10%;
    }
}


@media screen and (max-width: 840px) {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;

        margin: 2rem 10% 2rem 10%;
    }

    .imageContainer1 {
        width: 60%;
        height: auto;
    }
    
    .imageContainer2 {
        width: 60%;
        height: auto;
    }

    .title {
        align-self: center;
        text-align: center;
        
        margin: 2rem 0rem 0rem 0rem;
    }

    .description {
        align-self: center;
        text-align: center;

        margin: 2rem 0rem 0rem 0rem;
    }

    .contentContainer {
        margin: 0rem 10% 0rem 10%;
    }

    .content {
        margin: 1rem 0rem 1rem 0rem;
    }

    .button {
        margin: 4rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    .container {
        margin: 2rem 7.5% 2rem 7.5%;
    }

    .imageContainer1 {
        width: 75%;
        height: auto;
    }
    
    .imageContainer2 {
        width: 75%;
        height: auto;
    }

    .contentContainer {
        margin: 0rem 7.5% 0rem 7.5%;
    }
}


@media screen and (max-width: 600px) {
    .container {
        margin: 0rem 7.5% 2rem 7.5%;
    }

    .imageContainer1 {
        width: 90%;
        height: auto;
    }
    
    .imageContainer2 {
        width: 90%;
        height: auto;
    }

    .title {
        font-size: var(--fontSizeTitleS);
    }
}


@media screen and (max-width: 480px) {
    .container {
        margin: 0rem 5% 2rem 5%;
    }

    .imageContainer1 {
        width: 100%;
        height: auto;
    }
    
    .imageContainer2 {
        width: 100%;
        height: auto;
    }

    .title {
        align-self: flex-start;
        text-align: start;
        
        margin: 2rem 0rem 0rem 0rem;
    }

    .description {
        align-self: flex-start;
        text-align: start;

        margin: 2rem 0rem 0rem 0rem;
    }

    .contentContainer { 
        margin: 0rem 5% 0rem 5%;
    }

    .content {
        align-self: flex-start;
        text-align: start;
    }

    .button {
        margin: 2rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 360px) {
    .title {
        font-size: var(--fontSizeTitleXS);
        
        margin: 2rem 0rem 0rem 0rem;
    }

    .description {
        font-size: var(--fontSizeTextS);

        margin: 2rem 0rem 0rem 0rem;
    }

    .content {
        font-size: var(--fontSizeTextS);

        margin: 1rem 0rem 1rem 0rem;
    }
}
