
/*  Hero Section  */
.heroSection {
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
}

/*  Contact Us Page  */
.contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    position: relative;
    z-index: 1;
    width: 100%;
    background: #FFFFFF;

    padding: 4rem 0rem 4rem 0rem;
}

.contactSection {
    display: grid;
    justify-content: center;

    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0%;

    width: 100%;
    max-width: 200rem;

    padding-top: 8rem;

    margin: 0rem 0rem 12rem 0rem;
}

/* Contact Form Section */
.contactFormContainer {
    grid-column: 1 / 2;
    grid-row: 1;

    width: 100%;
}

/* Details Section */
.detailsContainer {
    grid-column: 2 / 3;
    grid-row: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;

    margin: 2rem 0rem 0rem 0rem;
}

.addressContainer {
    margin: 4rem 0rem 0rem 0rem;
}

.addressTitle {
    color: #222222;
    font-size: var(--fontSizeTitleXS);
    font-weight: var(--fontWeightXL);

    margin: 0rem 0rem 0rem 0rem;
}

.addressHolder {
    margin: 0rem 0rem 0rem 0rem;
}

.address {
    font-style: normal;
    font-weight: var(--fontWeightM);
    font-size: var(--fontSizeTextM);
    letter-spacing: var(--letterSpacingA);
    color: #222222;

    margin: 2rem 0rem 0rem 1rem;
}

.emailContainer {
    margin: 1rem 0rem 0rem 0rem;
}

.emailTitle {
    color: #222222;
    font-size: var(--fontSizeTitleXS);
    font-weight: var(--fontWeightXL);

    margin: 0rem 0rem 0rem 0rem;
}

.emailAddress {
    font-style: normal;
    font-weight: var(--fontWeightM);
    font-size: var(--fontSizeTextM);
    letter-spacing: var(--letterSpacingC);
    color: #222222;

    margin: 2rem 0rem 0rem 1rem;
}




@media screen and (max-width: 1200px) {
    /* Details Section */
    .emailContainer {
        margin: 0.5rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 840px) {
    /*  Contact Us Page  */
    .contactContainer {
        padding: 4rem 0rem 0rem 0rem;
    }

    .contactSection {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    
        width: 100%;

        padding-top: 4rem;
    
        margin: 0rem 0rem 12rem 0rem;
    }

    /* Details Section */
    .detailsContainer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        width: 75%;
        margin: 0rem 0rem 0rem 0rem;
    }

    .addressContainer {
        margin: 0rem 0rem 0rem 0rem;
    }

    .emailContainer {
        margin: 0rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 720px) {
    /*  Contact Us Page  */
    .contactSection {
        padding-top: 3rem;
        margin: 0rem 0rem 10rem 0rem;
    }

    /* Details Section */
    .detailsContainer {
        width: 80%;
    }
}


@media screen and (max-width: 600px) {
    /*  Contact Us Page  */
    .contactSection {
        padding-top: 2.5rem;
    }

    /* Details Section */
    .detailsContainer {
        width: 87.5%;
    }
}


@media screen and (max-width: 480px) {
    /*  Contact Us Page  */
    .contactSection {
        padding-top: 2rem;
        margin: 0rem 0rem 8rem 0rem;
    }

    /* Details Section */
    .detailsContainer {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        width: 85%;
    }

    .addressTitle {
        font-size: var(--fontSizeText2XL);
    }

    .address {
        font-size: var(--fontSizeTextS);
        color: #222222;
    }

    .emailContainer {
        margin: 3rem 0rem 0rem 0rem;
    }

    .emailTitle {
        font-size: var(--fontSizeText2XL);
    }
    
    .emailAddress {
        font-size: var(--fontSizeTextS);
    }
}
