
.card {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;

    width: 65rem;
    height: 40rem;
    
    background-image: url('../../assets/images/hero_circle_large.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    padding: 1rem;
    background-color: #ffffff;
    border: 0.1rem solid #dadada;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0.2rem 0.2rem 1rem #999999;
    -moz-box-shadow: 0.2rem 0.2rem 1rem #999999;
    box-shadow: 0.2rem 0.2rem 1rem #999999;
    transition: all 0.2s ease-in;
    cursor: pointer;
}

.card:hover {
    transform: translateY(-0.2%);
    -webkit-box-shadow: 0.4rem 0.4rem 1.6rem #797979;
    -moz-box-shadow: 0.4rem 0.4rem 1.6rem #797979;
    box-shadow: 0.4rem 0.4rem 1.6rem #797979;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    cursor: default;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;

    padding-left: 1rem;

    grid-column: 1;
    grid-row: 3;
}

.title {
    color: #ffffff;
    font-size: var(--fontSizeTitleS);
    font-weight: var(--fontWeight2XL);

    padding-left: 0.5rem;
    margin: 0rem 0rem 0rem 0rem;
}

.description {
    color: #ffffff;
    font-size: var(--fontSizeTextS);
    font-weight: var(--fontWeightL);
    letter-spacing: var(--letterSpacingC);

    padding-left: 0.5rem;
    margin: 0rem 0rem 0rem 0rem;
}

.button {
    margin: 1rem 0rem 1rem 0rem;
}



@media screen and (max-width: 1440px) {
    .card {
        width: 56rem;
        height: 34.5rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 1200px) {
    .card {
        width: 50rem;
        height: 30.8rem;
    
        padding: 1rem;

        border-radius: 1rem;
    }
    
    .title {
        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 1080px) {
    .card {
        width: 45rem;
        height: 27.7rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingB);
    }
}


@media screen and (max-width: 960px) {
    .card {
        width: 65rem;
        height: 40rem;
    
        padding: 1rem;

        border-radius: 1.5rem;
    }
    
    .title {
        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 720px) {
    .card {
        width: 56rem;
        height: 34.5rem;
    
        padding: 1rem;
    }

    .container {
        padding-left: 0rem;
    }
    
    .title {
        font-size: var(--fontSizeTitleXS);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextS);
        font-weight: var(--fontWeightL);
    }

    .button {
        margin: 1rem 0rem 0.5rem 0rem;
    }
}


@media screen and (max-width: 600px) {
    .card {
        width: 44rem;
        height: 27.1rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: var(--fontSizeText2XL);
        font-weight: var(--fontWeight2XL);
        padding-left: 0rem;
    }
    
    .description {
        font-size: var(--fontSizeTextXS);
        font-weight: var(--fontWeightL);
        padding-left: 0rem;
    }

    .button {
        margin: 1rem 0rem 0rem 0rem;
    }
}


@media screen and (max-width: 480px) {
    .card {
        width: 40rem;
        height: 24.6rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: var(--fontSizeText2XL);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextXS);
        font-weight: var(--fontWeightL);
    }
}


@media screen and (max-width: 420px) {
    .card {
        width: 34rem;
        height: 20.9rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: var(--fontSizeTextXL);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextXS);
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingA);
    }
}


@media screen and (max-width: 360px) {
    .card {
        width: 30rem;
        height: 18.5rem;
    
        padding: 1rem;
    }
    
    .title {
        font-size: var(--fontSizeTextL);
        font-weight: var(--fontWeight2XL);
    }
    
    .description {
        font-size: var(--fontSizeTextXS);
        font-weight: var(--fontWeightL);
        letter-spacing: var(--letterSpacingA);
    }
}